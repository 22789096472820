import { PlanType } from "../reducers/models";

export const BBDownloadVersion: bb.utility.IBBDownloadVersions = {
  BB4: {
    WIN: process.env.REACT_APP_BB4_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_BB4_DOWNLOAD_LINK_MAC,
    APP: "buildbox4",
  },  
  BB3: {
    WIN: process.env.REACT_APP_BB3_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_BB3_DOWNLOAD_LINK_MAC,
    APP: "buildbox3",
  },
  BB2: {
    WIN: process.env.REACT_APP_BB2_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_BB2_DOWNLOAD_LINK_MAC,
    APP: "buildbox2",
  },
  Soundbox: {
    WIN: process.env.REACT_APP_SOUNDBOX_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_SOUNDBOX_DOWNLOAD_LINK_MAC,
    APP: "soundbox",
  },
  Animationbox: {
    WIN: process.env.REACT_APP_ANIMATIONBOX_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_ANIMATIONBOX_DOWNLOAD_LINK_MAC,
    APP: "animationbox",
  },
  Pixelbox: {
    WIN: process.env.REACT_APP_PIXELBOX_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_PIXELBOX_DOWNLOAD_LINK_MAC,
    APP: "pixelbox",
  },
  Voxelbox: {
    WIN: process.env.REACT_APP_VOXELBOX_DOWNLOAD_LINK_WIN,
    MAC: process.env.REACT_APP_VOXELBOX_DOWNLOAD_LINK_MAC,
    APP: "voxelbox",
  }
};

export const downloadPaths: bb.utility.DownloadPaths = {
  BB4: "downloads/bb4",
  BB3: "downloads/bb3",
  BB2: "downloads/bb2",
  BBBundle: "downloads/bbbundle",
  Soundbox: "downloads/soundbox",
};

export const analyticProductTypes: bb.utility.BBProductTypes = {
  BB4: "buildbox4",
  BB3: "buildbox3",
  BB2: "buildbox2",
  BBBundle: "buildboxbundle",
  Soundbox: "soundbox",
};

type AnalyticsProductTierNames<PlanType extends string, U> = {
  [K in PlanType]: U;
};

export const analyticProductTierNames: AnalyticsProductTierNames<PlanType, string> = {
  Monthly: "Monthly",
  Free: "Free",
  Plus: "Plus",
  Pro: "Pro",
  Bundle: "Pro",
  //Weekly: "Weekly",
  Beginner: "Beginner",
  Basic: "Plus",
}

